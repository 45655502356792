<template>
  <page title="CodeDrills Career" :apiStatusList="[]"> Coming Soon ... </page>
</template>
<script>
import Page from '../components/Page'
export default {
  components: {
    Page,
  },
}
</script>
